.banner {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.slogan-align {
    width: 100%;
}

.slogan {
    width: 1240px;
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    color: #123859;
    margin-bottom: 20px;
    margin-top: 0px;
}

.all-banner-align {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.banner-align {
    display: flex;
    align-items: center;
    height: 905px;
    flex-direction: column;
}

.banner-content {
    display: flex;
    width: 100%;
    height: 100%;
}


.image-banner {
    display: flex;
    width: 1706.54px;
    height: 100%;
    /*background-image: url(../image/banner.jpg); */
    object-fit: cover;
}

.link-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    width: 443.46px;
    height: 905px;
    background: #C6F227;
    cursor: pointer;
    text-decoration: none;
}

.link-div {
    width: 154px;
    height: 460px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 125px;
}

.link-banner {
    display: flex;
    width: 460px;
    height: 154px;
    align-items: center;
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 128px;
    line-height: 120%;
    text-align: center;
    color: #123859;
    transform: rotate(90deg);
}

.arrow-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.arrow-text {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    color: #123859;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.arrow {
    width: 53px;
    height: 63px;
}

.link-block:hover .arrow-text{
    margin-bottom: 20px;
}

.itemBanner{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 7px;
}

.bannerItem{
    display: flex;
    height: 818px;
}

.itemContent{
    gap:50px;
}