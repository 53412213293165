.item {
    width: 419px;
    height: 777px;
    display: flex;
    margin-bottom: 7px;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;
}

.cardImage {
    background-size: cover;
    background-position: center;
    width: 420px;
    height: 563px;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.item:hover .cardImage{
    margin-bottom: 15px;
}

.itemName {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    color: #123859;
}

.price {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 120%;
    color: #F26E50;
}

.moreBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 70px;
    background: #123859;
	transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.item:hover .moreBlock{
    gap: 30px;
}

.moreBlockAlign {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 30px 0px 0px;
    gap: 10px;
    width: 100%;
    height: 70px;
}

.moreText {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.arrowMore {
    padding-top: 2px;
    width: 28px;
}