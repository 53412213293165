.page404 {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text404 {
    display: flex;
    color: #F26E50;
    font-size: 500px;
    font-family: Srbija Sans;
    font-style: normal;
    font-weight: 400;
    margin-top: 100px !important;
    margin: 0;
    opacity: 0.5;
}

.notFound {
    color: #123859;
    font-size: 105px;
    font-family: Roboto Flex;
    font-style: normal;
    font-weight: 500;
    margin: 0;
}