.footer {
    width: 100%;
    height: 313px;
    background: #123859;
    margin-top: 100px;
    padding-bottom: 30px;
}

.logoFooter {
    width: 184.95px;
    height: 189.815px;
    display: flex;
    margin-top: 62px;
    margin-left: 42px;
}

.date {
    color: #FFF;
    font-size: 40px;
    font-family: Roboto Flex;
    font-weight: 500;
    line-height: 120%;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    display: flex;
    padding-left: 7%;
}

.adress {
    color: #FFF;
    font-size: 40px;
    font-family: Roboto Flex;
    font-weight: 500;
    line-height: 120%;
    display: flex;
}

.vixi{
    color: #FFF;
    font-size: 40px;
    font-family: Roboto Flex;
    font-weight: 500;
    line-height: 120%;
    display: flex;
}

.number{
    color: #FFF;
    font-size: 40px;
    font-family: Roboto Flex;
    font-weight: 500;
    line-height: 120%;
    display: flex;
}
.aboutFooter {
    display: flex;
    justify-content: center;
    align-items: left;
    width: 900px;
    flex-direction: column;
}

.alignFooter{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    justify-content: center;
}