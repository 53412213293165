.itemBlock {
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.alignBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.itemText {
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    color: #123859;
}

.itemsBlock {
    width: 2149.970px;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    align-items: center;
    justify-content: left;
    flex-direction: row;
    margin-bottom: 20px;
}


.itemTextBoxAlign {
    width: 2149.970px;
    justify-content: start;
    flex-direction: column;
}

.hoverTextBox:hover .itemArrowText{
    padding-left: 30px;
}


.itemTextAlign {
    cursor: pointer;
    width: 440px;
    display: flex;
    align-items: center;
}

.itemArrowText {
    width: 25px;
    height: 26px;
    padding-top: 5px;
    padding-left: 20px;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.itemTextLine {
    background-color: #123859;
    width: 409px;
    height: 5px;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.hoverTextBox:hover .itemTextLine{
    width: 419px;
}

.hoverTextBox{
    display: block;
    width: 440px;
    text-decoration: none;
}

.itemsPage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}