html {
    zoom: 0.18;
}

@media (min-width: 540px) {
    html {
        zoom: 0.25;
    }
}

@media (min-width: 640px) {
    html {
        zoom: 0.3;
    }
}

@media (min-width: 940px) {
    html {
        zoom: 0.4;
    }
}

@media (min-width: 1140px) {
    html {
        zoom: 0.5;
    }
}

@media (min-width: 1340px) {
    html {
        zoom: 0.6;
    }
}

@media (min-width: 1540px) {
    html {
        zoom: 0.7;
    }
}

@media (min-width: 1780px) {
    html {
        zoom: 0.8;
    }
}

@media (min-width: 1960px) {
    html {
        zoom: 0.9;
    }
}

@media (min-width: 2220px) {
    html {
        zoom: 1;
    }
}

.NavLink {
    text-decoration: none;
}

/* Webfont: SrbijaSans-Regular */
@font-face {
    font-family: 'Srbija Sans';
    src: local('Srbija Sans'),
        url('../fonts/Srbija Sans.woff') format('woff'),
        /* Modern Browsers */
        url('../fonts/Srbija Sans.ttf') format('truetype');
    /* Safari, Android, iOS */
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
}