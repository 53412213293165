.galleryBox {
    display: flex;
    width: 2149.970px;
    height: 100%;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    gap: 5px;
}

.galleryImage {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100%;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    cursor: zoom-in;
}

.galleryImage:hover {
    opacity: 0.8;
}

.Vertical {
    display: flex;
    width: 400px;
    height: 573px;
    background-size: cover;
    background-position: center;
}

.Horizontal {
    display: flex;
    width: 500px;
    height: 573px;
    background-size: cover;
    background-position: center;
}

.galleryH1 {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    color: #123859;
    font-size: 105px;
    font-family: Srbija Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.bgDiv {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgImage{
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 98;
    cursor: zoom-out;
}

.zoomImage{
    position: fixed;
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
    z-index: 99;
    border-radius: 20px;
}