.currentItemAlign {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    zoom: 0.9;
}

.itemImage {
    display: flex;
    width: 500px;
    height: 700px;
    background-size: cover;
    background-position: center;
}

.currentItemName {
    width: 2149.970px;
    justify-content: center;
    color: #123859;
    font-size: 65px;
    font-family: Srbija Sans;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.itemDiv {
    display: flex;
    flex-direction: row;
    gap: 100px;
    width: 2149.970px;
    height: 100%;
}

.parametersDiv {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    font-size: 40px;
    font-family: Roboto Flex;
    font-style: normal;
    gap: 20px;
}

.currentContent {
    display: flex;
    padding-top: 100px;
}

.parameterText {
    margin-right: 20px;
    color: #123859;
    font-weight: 500;
}

.parameterValue {
    color: #F26E50;
    white-space: nowrap;
}

.description {
    display: flex;
    padding-top: 100px;
    color: #123859;
    font-size: 30px;
    font-family: Roboto Flex;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    display: flex;
    width: 917px;
    flex-direction: column;
    align-self: stretch;
}

.orderBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 316px;
    height: 60px;
    background: #F26E50;
	transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    cursor: pointer;
}

.orderBlock:hover{
    gap: 30px;
}

.bgText{
    display: flex;
    border-radius: 25px;
    width: 1000px;
    height: 200px;
    background-color: white;
    z-index: 99;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.tel{
    display: flex;
    margin-right: 20px;
    color: #000000;
    font-weight: 500;
    font-size: 40px;
    font-family: Roboto Flex;
    justify-items: center;
    align-items: center;
    text-align: center;
}

.telText{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
