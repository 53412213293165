.cardMini {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 429px;
    height: 820px;
    overflow: hidden;
}

.imageCardMini {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardMiniBG {
    display: flex;
    position: absolute;
    width: 478px;
    height: 100%;
    left: -429px;
    flex-direction: row;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.cardMini:hover .cardMiniBG {
    left: 0px;
}

.huita{
    position: absolute;
    bottom: 50px;
    left: 45%;
    right: 55%;
}

.cardMiniH1Box {
    width: 56px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardMiniH1 {
    white-space: nowrap;
    position: relative;
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
}

.cardMiniTextAlign {
    padding-top: 10px;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(-90deg);
}

.cardMiniContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px 45px;
    gap: 280px;
}

.cardMiniText {
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
}

.cardMiniMore{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.cardMiniBG:hover .cardMiniMore{
    gap: 10px;
}

.cardMiniMoreText {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    color: #ffff;
}

.arrowMoreMini{
    padding-top: 1px;
    width: 28px;
}