.servicesBlockAlign {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-direction: column;
}

.servicesText {
    width: 835px;
    height: 156px;
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 65px;
    line-height: 120%;
    text-align: center;
    color: #123859;
    margin-bottom: 50px;
}

.servicesCardBoxAlign {
    align-items: center;
    width: 2149.970px;
    display: flex;
    justify-content: center;
}

.servicesCardBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 0px;
    flex-wrap: wrap;
}