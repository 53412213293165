.cardMax {
    cursor: pointer;
    position: relative;
    display: flex;
    width: 858px;
    height: 820px;
    overflow: hidden;
}

.imageCardMax {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardMaxBG {
    display: flex;
    position: absolute;
    width: 858px;
    height: 115%;
    bottom: -820px;
    flex-direction: column;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.cardMax:hover .cardMaxBG {
    bottom: 0px;
}

.huita {
    position: absolute;
    bottom: 50px;
    left: 45%;
    right: 55%;
}

.cardMaxH1Box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardMaxH1 {
    position: relative;
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    width: 823px;
    justify-content: center;
}

.cardMaxTextAlign {
    padding-top: 2%;
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardMaxContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.Pomogite{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.cardMaxText {
    font-family: 'Srbija Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
    width: 770px;
    display: flex;
    justify-content: center;
}

.cardMaxMore {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.cardMaxBG:hover .cardMaxMore {
    gap: 10px;
}

.cardMaxMoreText {
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 120%;
    text-align: center;
    color: #ffff;
}

.arrowMoreMax {
    padding-top: 1px;
    width: 28px;
}