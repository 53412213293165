.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 150px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.header-align{
    width: 2149.970px;
    display: flex;
    align-items: center;
    gap: 100px;

}

.image-logo {
    width: 148.69px;
    height: 152.6px;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 0px 0px;
    gap: 50px;
    width: 100%;
    height: 78px;
}

.link {
    width: 183px;
    height: 58px;
    opacity: 0.5;
    font-weight: 600;
    font-family: 'Roboto Flex', sans-serif;
    font-style: normal;
    font-size: 48px;
    line-height: 120%;
    color: #123859;
    cursor: pointer;
    text-decoration: none;
    transition: 0.6s cubic-bezier(0.22, 1, 0.36, 1);
}

.link:hover {
    opacity: 0.6;
}

.link.active {
    opacity: 1;
}
